import { React, useState } from "react";
import { Container } from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";

import CaseStudiesMap from "../../components/CaseStudiesMap";
import ClientsFooter from "../../components/ClientsFooter";

import BupaPhoto from "./../../img/Work Page (Case Study Overview)/jx_bupa_health_insuarance_mobile_ux.svg";
import YalumbaPhoto from "./../../img/Work Page (Case Study Overview)/jx_yalumba_winery_wine_ecommerce_mobile_uxui_service_design_product.svg";
import ANZPhoto from "./../../img/Work Page (Case Study Overview)/jx_anz_banking_transaction_currency_system_desktop_uxui_digital_service_design_information_archutecture1.svg";
import AusPostPhoto from "./../../img/Work Page (Case Study Overview)/jx_australiapost_mail_delivery_journeymap_design.svg";
import AGLPhoto from "./../../img/Work Page (Case Study Overview)/jx_agl_energy_gas_electricity_solar_desktop_uxui_service_design.svg";
import miraclePalmPhoto from "./../../img/Work Page (Case Study Overview)/jx_hva_miracle_palm_coconut_milk_packaging_design.svg";
import LacrossePhoto from "./../../img/Work Page (Case Study Overview)/jx_lacrosse_realestate_Appartment_digital_sinage_design_tram_tracker_screen.svg";
import ParachuutePhoto from "./../../img/Work Page (Case Study Overview)/jx_parachuute_rideshare_mobile_uxui_design.svg";

const CaseStudies = () => {
  const caseStudies1 = [
    {
      title: "Bupa",
      subtitle: "Digital Service Design",
      img: BupaPhoto,
      height: "460px",
      style: {
        marginBottom: 95,
      },
    },
    {
      title: "Yalumba",
      subtitle: "UX & Digital Service Design",
      img: YalumbaPhoto,
      height: "510px",
      style: {
        marginBottom: 158,
      },
    },
    {
      title: "AGL",
      subtitle: "UX & Digital Service Design",
      img: AGLPhoto,
      height: "510px",
      style: {
        marginBottom: 99,
      },
    },
    {
      title: "Miracle Palm",
      subtitle: "UX/UI & Digital Service Design",
      img: miraclePalmPhoto,
      height: "460px",
      style: {},
    },
  ];

  const caseStudies2 = [
    {
      title: "Australia Post",
      subtitle: "CX & Digital Service Design",
      img: AusPostPhoto,
      height: "510px",
      style: {
        marginBottom: 89,
      },
    },
    {
      title: "ANZ",
      subtitle: "UX Research & UI Design",
      img: ANZPhoto,
      height: "510px",
      style: {
        marginBottom: 89,
      },
    },
    {
      title: "Parachute",
      subtitle: "UX Research & Design",
      img: ParachuutePhoto,
      height: "460px",
      style: {
        marginBottom: 71,
      },
    },
    {
      title: "Lacrosse",
      subtitle: "UXUI Design & UI Development",
      img: LacrossePhoto,
      height: "430px",
      style: {},
    },
  ];
  return (
    <>
      <Container className="flexContainer">
        <div
          className="casestudies_container"
          style={{ marginTop: 129, marginBottom: 158 }}
        >
          <VisibilitySensor partialVisibility delayedCall>
            {({ isVisible }) => (
              <h1
                className={`reveal casestudies_h1 ${isVisible ? "active" : ""}`}
              >
                What we have designed
              </h1>
            )}
          </VisibilitySensor>
          <div className="homepage_cases_container casestudies">
            <div>
              <CaseStudiesMap cases={caseStudies1} />
            </div>
            <div>
              <CaseStudiesMap cases={caseStudies2} />
            </div>
          </div>
        </div>
      </Container>
      <ClientsFooter />
    </>
  );
};

export default CaseStudies;
