import React from "react";
import { Container } from "react-bootstrap";

import Heading from "./Heading";
import Steps from "./Steps";
import Outcome from "./Outcome";
import ClientsFooter from "../../components/ClientsFooter";

const Startup = () => {
  return (
    <>
      <Container>
        <div className="flexContainer startup">
          <Heading />
          <Steps />
          <Outcome />
        </div>
      </Container>
      <ClientsFooter />
    </>
  );
};

export default Startup;
