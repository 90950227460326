import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CaseStudiesMap = (props) => {
  return (
    <>
      {props.cases.map((el, idx) =>
        idx === 0 ? (
          <section
            key={idx}
            className="homepage_cases_section"
            style={el.style}
            onMouseOver={(ev) => {
              let subtitle = ev.currentTarget.querySelector(
                ".homepage_cases_subtitle"
              );
              subtitle.classList.add("hover");
            }}
            onMouseLeave={(ev) => {
              let subtitle = ev.currentTarget.querySelector(
                ".homepage_cases_subtitle"
              );
              subtitle.classList.remove("hover");
            }}
          >
            <VisibilitySensor partialVisibility delayedCall>
              {({ isVisible }) => (
                <>
                  <div className={`reveal ${isVisible ? "active" : ""}`}>
                    <a
                      aria-label={`Read more about the ${el.title} case study`}
                      href={"/case-studies/" + el.title}
                    >
                      <LazyLoadImage
                        effect="blur"
                        alt={el.title}
                        className="grow homepage_cases_img"
                        src={el.img}
                        height={el.height}
                      />
                    </a>
                  </div>
                  <a
                    aria-label={`Read more about the ${el.title} case study`}
                    href={"/case-studies/" + el.title}
                  >
                    <h4 className="reveal homepage_cases_title">
                      <span className="blueHover">{el.title}</span>
                    </h4>
                  </a>
                  <h5 className="homepage_cases_subtitle">{el.subtitle}</h5>
                </>
              )}
            </VisibilitySensor>
          </section>
        ) : (
          <section
            key={idx}
            className="homepage_cases_section"
            style={el.style}
            onMouseOver={(ev) => {
              let subtitle = ev.currentTarget.querySelector(
                ".homepage_cases_subtitle"
              );
              subtitle.classList.add("hover");
            }}
            onMouseLeave={(ev) => {
              let subtitle = ev.currentTarget.querySelector(
                ".homepage_cases_subtitle"
              );
              subtitle.classList.remove("hover");
            }}
          >
            <div className="reveal">
              <a
                aria-label={`Read more about the ${el.title} case study`}
                href={"/case-studies/" + el.title}
              >
                <LazyLoadImage
                  effect="blur"
                  alt={el.title}
                  className="grow homepage_cases_img"
                  src={el.img}
                  height={el.height}
                />
              </a>
            </div>
            <a
              aria-label={`Read more about the ${el.title} case study`}
              href={"/case-studies/" + el.title}
            >
              <h4 className="reveal homepage_cases_title">
                <span className="blueHover">{el.title}</span>
              </h4>
            </a>
            <h5 className="homepage_cases_subtitle">{el.subtitle}</h5>
          </section>
        )
      )}
    </>
  );
};

export default CaseStudiesMap;
