import { React, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import uiDevelopmentImg from "../../img/Solutions Page/Development/jx_design_ui_development_coding.jpg";
import backendDevelopmentImg from "../../img/Solutions Page/Development/jx_design_backend_development_coding.jpg";
import systemIntegrationImg from "../../img/Solutions Page/Development/jx_design_system_integration_development_coding.jpg";
import systemMaintenanceImg from "../../img/Solutions Page/Development/jx_design_system_maintainance_development_coding.jpg";

const Development = () => {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  return (
    <>
      {/* section-1 */}
      <VisibilitySensor
        partialVisibility
        offset={{ bottom: 100 }}
        active={!viewPortEntered}
        onChange={(isVisible) => {
          if (isVisible) {
            setViewPortEntered(true);
          }
        }}
        delayedCall
      >
        {({ isVisible }) => (
          <section
            className="solutions_center_content_section"
            style={{ marginBottom: 108 }}
          >
            <div style={{ marginRight: 85.5 }}>
              <h5
                className={`reveal solutions_center_content_h5 ${
                  isVisible ? "active" : ""
                }`}
              >
                UI Development
              </h5>
              <p className="solutions_center_content_p">
                <span
                  style={{ transitionDelay: "20ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  After our process of design, we develop
                </span>
                <span
                  style={{ transitionDelay: "60ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  and deliver a user driven solution, fit for
                </span>
                <span
                  style={{ transitionDelay: "80ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  your organisation’s needs. Whether for
                </span>
                <span
                  style={{ transitionDelay: "100ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  mobile, web, or beyond, you will find
                </span>
                <span
                  style={{ transitionDelay: "120ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  consistency and reliability as we engineer
                </span>
                <span
                  style={{ transitionDelay: "140ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  your product to go live. With constant
                </span>
                <span
                  style={{ transitionDelay: "160ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  improvement in mind, we build your
                </span>
                <span
                  style={{ transitionDelay: "180ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  product with the latest technologies in
                </span>
                <span
                  style={{ transitionDelay: "200ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  HTML, CSS, React, and Angular.
                </span>
              </p>
            </div>
            <div
              style={{ transitionDelay: "40ms", background: "#D9D9D9" }}
              className={`reveal solutions_center_content_img ${
                isVisible ? "active" : ""
              }`}
            >
              <img
                className="solutions_center_content_img"
                src={uiDevelopmentImg}
                alt="UI Development with Mac"
              />
            </div>
          </section>
        )}
      </VisibilitySensor>
      {/* section-2 */}
      <section
        style={{ marginBottom: 108 }}
        className="solutions_center_content_section"
      >
        <div
          className="reveal solutions_center_content_img"
          style={{ background: "#D9D9D9", marginRight: 78 }}
        >
          <img
            className="solutions_center_content_img"
            src={backendDevelopmentImg}
            alt="Backend Development code"
          />
        </div>
        <div>
          <h5 className="reveal solutions_center_content_h5">
            Back End Development
          </h5>
          <p className="solutions_center_content_p">
            <span className="reveal">From simple to complex, we deliver</span>
            <span className="reveal">products that bridge the digital gap</span>
            <span className="reveal">between you and your customer.</span>
            <span className="reveal">
              Businesses of any size are impacted by the
            </span>
            <span className="reveal">
              critical functions of a backend system, and
            </span>
            <span className="reveal">with multiple products designed and</span>
            <span className="reveal">deployed, our team will connect your</span>
            <span className="reveal">
              databases with what you see on the screen.
            </span>
          </p>
        </div>
      </section>
      {/* section-3 */}
      <section
        style={{ marginBottom: 149 }}
        className="solutions_center_content_section"
      >
        <div style={{ marginRight: 104.5 }}>
          <h5 className="reveal solutions_center_content_h5">
            System Integration
          </h5>
          <p className="solutions_center_content_p">
            <span className="reveal">
              Tailored to your specific needs, we will
            </span>
            <span className="reveal">
              integrate all of your business systems,
            </span>
            <span className="reveal">developing a complete and seamless</span>
            <span className="reveal">
              experience for you and your user. All
            </span>
            <span className="reveal">
              aspects of your system will be running
            </span>
            <span className="reveal">
              concurrently, delivering an ease of use.
            </span>
          </p>
        </div>
        <div
          className="reveal solutions_center_content_img"
          style={{ background: "#D9D9D9" }}
        >
          <img
            className="solutions_center_content_img"
            src={systemIntegrationImg}
            alt="System Integration"
          />
        </div>
      </section>
      {/* section-4 */}
      <section className="solutions_center_content_section">
        <div
          className="reveal solutions_center_content_img"
          style={{ background: "#D9D9D9", marginRight: 78 }}
        >
          <img
            className="solutions_center_content_img"
            src={systemMaintenanceImg}
            alt="System Maintenance Office"
          />
        </div>
        <div>
          <h5 className="reveal solutions_center_content_h5">
            System Maintenance
          </h5>
          <p className="solutions_center_content_p">
            <span className="reveal">
              Our goal is to ensure you will continually
            </span>
            <span className="reveal">have an optimal system by providing</span>
            <span className="reveal">
              ongoing assistance and maintenance. We
            </span>
            <span className="reveal">
              will keep your system running efficiently.
            </span>
            <span className="reveal">
              With our management and availability, we
            </span>
            <span className="reveal">will be an extension of your team,</span>
            <span className="reveal">affirming smooth sailing.</span>
          </p>
        </div>
      </section>
    </>
  );
};

export default Development;
