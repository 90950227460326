import { React, useState, useEffect, lazy } from "react";
import { Container } from "react-bootstrap";

const Heading = lazy(() => import("./Heading"));
const What = lazy(() => import("./What"));
const CaseStudies = lazy(() => import("./CaseStudies"));
const Clients = lazy(() => import("./Clients"));
const Process = lazy(() => import("./Process"));
const Aboutus = lazy(() => import("./Aboutus"));
const Youtube = lazy(() => import("./Youtube"));

const Homepage = () => {
  const [showYoutube, setShowYoutube] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1100) {
        setShowYoutube(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowYoutube(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const toggleYoutube = () => {
    setShowYoutube(!showYoutube);
  };
  return (
    <>
      <Youtube toggleYoutube={toggleYoutube} showYoutube={showYoutube} />
      <Container className="homepage">
        <Heading toggleYoutube={toggleYoutube} showYoutube={showYoutube} />
        <What />
        <Process />
        <Clients />
        <CaseStudies />
        <Aboutus />
      </Container>
    </>
  );
};

export default Homepage;
