import { React, useEffect } from "react";

const MeetJx = () => {
  useEffect(() => {
    let onLoadSelector = document.querySelectorAll(".onLoadTransition");
    for (let i = 0; i < onLoadSelector.length; i++) {
      setTimeout(() => {
        onLoadSelector[i].classList.add("active");
      }, 100 + 20 * i);
    }
  });
  return (
    <section className="aboutus_meetjx about_w1460">
      <h1 className="onLoadTransition reveal aboutus_h1 aboutus_heading">
        Meet JX
      </h1>
      <p className="aboutus_p aboutus_heading w414">
        <span className="onLoadTransition reveal">
          Loonum was founded by Dan, inspired by
        </span>
        <br />
        <span className="onLoadTransition reveal">
          a project where he felt passionate about
        </span>
        <br />
        <span className="onLoadTransition reveal">
          creating human focused products, so he
        </span>
        <br />
        <span className="onLoadTransition reveal">
          built Loonum to do just that. Talking for
        </span>
        <br />
        <span className="onLoadTransition reveal">
          hours with friends & mentors, ideating on
        </span>
        <br />
        <span className="onLoadTransition reveal">
          creating products with depth, fostered
        </span>
        <br />
        <span className="onLoadTransition reveal">Loonum’s core values,</span>
        <br />
        <span className="onLoadTransition reveal">
          and built an environment of constant
        </span>
        <br />
        <span className="onLoadTransition reveal">
          innovation with global insight.
        </span>
        <br />
        <br />

        <span className="onLoadTransition reveal">
          Years of constant innovation led us to the
        </span>
        <br />
        <span className="onLoadTransition reveal">
          niche of product design, and with this
        </span>
        <br />
        <span className="onLoadTransition reveal">focus we developed JX.</span>
        <br />
      </p>
      <p className="aboutus_p aboutus_heading w1460">
        <span className="onLoadTransition reveal">
          Loonum was founded by Dan, inspired by a project where he
        </span>
        <br />
        <span className="onLoadTransition reveal">
          felt passionate about creating human focused products, so he built
          Loonum to
        </span>
        <br />
        <span className="onLoadTransition reveal">
          do just that. Talking for hours with friends & mentors, ideating on
          creating
        </span>
        <br />
        <span className="onLoadTransition reveal">
          products with depth, fostered Loonum’s core values,
        </span>
        <br />
        <span className="onLoadTransition reveal">
          and built an environment of constant innovation with global insight.
        </span>
        <br />
        <span className="onLoadTransition reveal"></span>
        <br />
        <span className="onLoadTransition reveal">
          Years of constant innovation led us to the niche of product design,
          and with
        </span>
        <br />
        <span className="onLoadTransition reveal">
          this focus we developed JX.
        </span>
      </p>
    </section>
  );
};

export default MeetJx;
