import { React, useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Container } from "react-bootstrap";
import * as DOMPurify from "dompurify";

const Faq = () => {
  const [isWidth415, setIsWidth415] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsWidth415(window.innerWidth <= 993);
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [viewPortEntered, setViewPortEntered] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [faq, setFaq] = useState([
    {
      title: "What areas do you service?",
      descriptionMobile: `
      <span>Currently innovating from Los Angeles </span><br/>
      <span>and operating globally. </span><br/>
      <span>With offices in Los Angeles </span><br/>
      <span>and Melbourne, everyone is within reach.</span>`,
      description:
        "<span>Currently innovating from Los Angeles and operating globally. </span><br/><span>With offices in Los Angeles and Melbourne, everyone is within reach</span>.",
      collapse: false,
      transitionDelay: "160ms",
      heightMobile: 104,
      height: 76,
    },
    {
      title: "Why JX Design?",
      descriptionMobile: `
      <span>With an environment of constant innovation </span><br/>
      <span>with global insight, we are expansive and efficient.</span><br/>
      <span>Providing personalized solutions, we will be like a </span><br/>
      <span>part of your team, ready to assist from research</span><br/>
      <span>to product launch</span>.`,
      description:
        "<span>With an environment of constant innovation with global insight, we are expansive and efficient.</span><br/><span> Providing personalized solutions, we will be like a part of your team, ready to assist from research to</span><br/><span> product launch</span>.",
      collapse: false,
      transitionDelay: "180ms",
      heightMobile: 110,
      height: 76,
    },
    {
      title: "What is the process for starting a project",
      titleMobile:
        "<span>What is the process for starting a </span><br/><span>project?</span>",
      descriptionMobile: `
      <span>Simply reach out, email us or call anytime, your first</span><br/>
      <span>consultation will always be free. After getting your</span><br/>
      <span>project scope we can provide you with a proposal</span>.`,
      description:
        "<span>Simply reach out, email us or call anytime, your first consultation will always be free. After getting your</span><br/><span> project scope we can provide you with a proposal</span>.",
      collapse: false,
      transitionDelay: "200ms",
      heightMobile: 100,
      height: 49,
    },
    {
      title: "How do I get a proposal for my project?",
      descriptionMobile: `
      <span>Send us an email and call with a summary of</span><br/> 
      <span>what you need done and then we will schedule a</span><br/>
      <span> meeting to discuss all of the details. After</span><br/> 
      <span>getting the project scope and all details necessary,</span><br/>
      <span>we will send you a project proposal.</span>`,
      description:
        "<span>Send us an email and call with a summary of what you need done and then we will schedule a</span><br/><span> meeting to discuss all of the details. After getting the project scope and all details necessary, we will</span><br/><span> send you a project proposal.</span>",
      collapse: false,
      transitionDelay: "220ms",
      heightMobile: 110,
      height: 76,
    },
    {
      title: "Do I need a UX Audit?",
      descriptionMobile: `
      <span>An UX audit will give us a detailed analysis</span><br/> 
      <span>on which areas of your product could benefit from</span><br/>
      <span>improvement, so your customers will alway have</span><br/> 
      <span>an up to date and seamless experience. Chat with</span><br/>
      <span>us to see just how much your product could benefit</span><br/>
      <span>from an UX Audit.</span>`,
      description:
        "<span>An UX audit will give us a detailed analysis on which areas of your product could benefit from</span><br/><span> improvement, so your customers will alway have an up to date and seamless experience. Chat with us</span><br/><span> to see just how much your product could benefit from an UX Audit.</span>",
      collapse: false,
      transitionDelay: "240ms",
      heightMobile: 140,
      height: 76,
    },
    {
      title: "What types of companies do you work with?",
      titleMobile: "What types of companies do you work<br/> with?",
      descriptionMobile: `
      <span>We work with companies of all sizes and in</span><br/> 
      <span>various stages of development. Always providing</span><br/>
      <span>flexibility and diverse expertise, get in</span><br/>
      <span>touch and see if we would be a good fit for</span><br/>
      <span>your project needs</span>.`,
      description:
        "<span>We work with companies of all sizes and in various stages of development. Always providing flexibility</span><br/><span> and diverse expertise, get in touch and see if we would be a good fit for your project needs</span>.",
      collapse: false,
      transitionDelay: "260ms",
      heightMobile: 150,
      height: 49,
    },
    {
      title: "How long will my project take?",
      descriptionMobile: `
      <span>We will always give you an estimated time</span><br/> 
      <span>frame when we have all of the project details.</span><br/> 
      <span>But, if you areworking on a specific target </span><br/>
      <span>date in mind, we will always work to meet your deadline.</span>`,
      description:
        "<span>We will always give you an estimated time frame when we have all of the project details. But, if you are</span><br/><span> working on a specific target date in mind, we will always work to meet your deadline</span>.",
      collapse: false,
      transitionDelay: "280ms",
      heightMobile: 100,
      height: 49,
    },
    {
      title: "What are the typical costs?",
      descriptionMobile: `
      <span>Your first consultation will always be free.</span><br/>
      <span>We are happy to chat and get to know your project</span><br/>
      <span>scope and we will share a proposal with you</span><br/> 
      <span>encompassing all the details necessary. We will</span><br/> 
      <span>then give the project cost when we begin</span><br/>
      <span>the project with you. Feel free to email</span><br/>
      <span>us for more information</span>.`,
      description:
        "<span>Your first consultation will always be free. We are happy to chat and get to know your project scope</span><br/><span> and we will share a proposal with you encompassing all the details necessary. We will then give the</span><br/><span> project cost when we begin the project with you. Feel free to email us for more information</span>.",
      collapse: false,
      transitionDelay: "300ms",
      heightMobile: 150,
      height: 76,
    },
    {
      title: "What can I expect the full process to look like?",
      titleMobile: "What can I expect the full process to <br/>look like?",
      descriptionMobile: `
      <span>Discover. Define. Design. Test. Deliver.</span><br/> 
      <span>Iterate. It’s our approach to every project,</span><br/> 
      <span>and it’s always custom to your individual</span><br/>
      <span>business needs. Collaboration and</span><br/> 
      <span>attentiveness is our goal, so you will</span><br/>
      <span>always be well informed along the way.</span>`,
      description:
        "<span>Discover. Define. Design. Test. Deliver. Iterate . It’s our approach to every project, and it’s always custom</span><br/><span> to your individual business needs. Collaboration and attentiveness is our goal, so you will always</span><br/><span> be well informed along the way.</span>",
      collapse: false,
      transitionDelay: "320ms",
      heightMobile: 160,
      height: 103,
    },
  ]);
  const handleCollapse = (idx) => {
    const _faq = structuredClone(faq);
    _faq.forEach((el) => {
      el.collapse = false;
    });
    if (faq[idx].collapse === false) _faq[idx].collapse = true;
    setFaq(_faq);
  };

  useEffect(() => {
    let onLoadSelector = document.querySelectorAll(".onLoadTransition");
    for (let i = 0; i < onLoadSelector.length; i++) {
      setTimeout(() => {
        onLoadSelector[i].classList.add("active");
      }, 100 + 20 * i);
    }

    const span = document.querySelectorAll(
      ".faq_description_p > span:not(.reveal)"
    );
    for (let i = 0; i < span.length; i++) {
      span[i].classList.add("dropReveal");
    }
  }, []);
  return (
    <>
      <Container className="faq_container">
        <div>
          <h1 className="onLoadTransition reveal faq_h1">FAQ</h1>
          <p
            className="faq_description_p w1460"
            style={{ whiteSpace: "nowrap", marginTop: 63, marginBottom: 83 }}
          >
            <span className="onLoadTransition reveal">
              Questions? Here are a few of our most asked inquiries.
            </span>
            <br />
            <span className="onLoadTransition reveal">
              This is the starting point, if what we covered doesn’t
            </span>
            <br />
            <span className="onLoadTransition reveal">
              answer your question, call or send an email. We should
            </span>
            <br />
            <span className="onLoadTransition reveal">have the answer.</span>
          </p>
          <p
            className="faq_description_p w414"
            style={{ whiteSpace: "nowrap", marginTop: 63, marginBottom: 83 }}
          >
            <span className="onLoadTransition reveal">
              Questions? ... Here are a few of our most{" "}
            </span>
            <br />
            <span className="onLoadTransition reveal">
              asked inquiries. This is the starting point,{" "}
            </span>
            <br />
            <span className="onLoadTransition reveal">
              if what we covered doesn’t answer your{" "}
            </span>
            <br />
            <span className="onLoadTransition reveal">
              question, call or send an email. We should{" "}
            </span>
            <br />
            <span className="onLoadTransition reveal">have the answer. </span>
            <br />
          </p>
          {faq.map((el, idx) => (
            <VisibilitySensor
              partialVisibility
              active={!viewPortEntered[idx]}
              onChange={(isVisible) => {
                if (isVisible) {
                  let _viewPortEntered = [...viewPortEntered];
                  _viewPortEntered[idx] = true;
                  setViewPortEntered(_viewPortEntered);
                }
              }}
              delayedCall
            >
              {({ isVisible }) => (
                <section key={idx}>
                  <div
                    style={{ transitionDelay: el.transitionDelay }}
                    className={`reveal ${isVisible ? "active" : ""}`}
                  >
                    <div
                      className="faq_title"
                      onClick={() => handleCollapse(idx)}
                    >
                      <p
                        className="blueHover"
                        style={{ display: "inline-block" }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              isWidth415 && el.titleMobile
                                ? el.titleMobile
                                : el.title
                            ),
                          }}
                          style={{ marginRight: 10 }}
                        ></span>
                        <span>
                          <i
                            className={`thinIcon fa fa-chevron-down fa-2xs ${
                              el.collapse ? "isCollapsed" : ""
                            }`}
                          ></i>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="faq_description_padding">
                    <div className="faq_description">
                      <p
                        className={`faq_description_p ${
                          el.collapse ? "isCollapsed" : ""
                        }`}
                        style={{
                          height: el.collapse
                            ? isWidth415
                              ? el.heightMobile
                              : el.height
                            : 0,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            isWidth415 ? el.descriptionMobile : el.description
                          ),
                        }}
                      ></p>
                    </div>
                  </div>
                </section>
              )}
            </VisibilitySensor>
          ))}
        </div>
      </Container>
    </>
  );
};

export default Faq;
