import { React, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

const handleReveal = () => {
  const reveals = document.querySelectorAll(".reveal");
  let revealPoint = 0;

  let windowHeight = window.innerHeight;
  for (let i = 0; i < reveals.length; i++) {
    let revealTop = reveals[i].getBoundingClientRect().top;
    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("active");
    }
  }
};

const App = () => {
  useEffect(() => {
    window.addEventListener("scroll", handleReveal);
    return () => window.removeEventListener("scroll", handleReveal);
  }, []);

  return (
    <>
      <main>
        <Header />
        <ScrollToTop />
        <Outlet />
        <Footer />
      </main>
    </>
  );
};

export default App;
