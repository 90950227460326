import { React, useEffect } from "react";
import * as DOMPurify from "dompurify";

const Values = () => {
  const values = [
    {
      title: "Leading By Example",
      description:
        '<span className="reveal">JX is a collaboration. This is why our core <br/>values are a blend of the team’s values.</span><br/> <span className="reveal">They define company culture and that <br/>defines the organization. Following our <br/>core values and what they mean to CEO <br/>Dan Perera and our Loonum family.</span>',
    },
    {
      title: "Get it done right",
      description:
        '<span className="reveal">We grow by learning from challenges,</span><br/><span className="reveal"> but that means we follow through & </span><br/><span className="reveal">make it right. It is important that we get </span><br/><span className="reveal">it done right as an organization and as </span><br/><span className="reveal">individuals so we can not only meet, but </span><br/><span className="reveal">exceed the expectations of our clients.</span>',
    },
    {
      title: "Trust and respect",
      description:
        '<span className="reveal">We respect and value each other’s</span><br/><span className="reveal"> opinions, creating a happier, healthier</span><br/><span className="reveal"> work environment. Our team is based on</span><br/><span className="reveal"> trust and respect. Leaders are confident</span><br/><span className="reveal"> that team members will ask for help</span><br/><span className="reveal"> when they need it, and everyone on the</span><br/><span className="reveal"> team respects and helps one another.</span>',
    },
    {
      title: "Positive Culture",
      description:
        '<span className="reveal">Miserable people and negative energy</span><br/><span className="reveal"> can infect an organization by bringing</span><br/><span className="reveal"> everybody else down. Tensions are</span><br/><span className="reveal"> inevitable in a fast paced agency but we</span><br/><span className="reveal"> make it a priority to have fun when we</span><br/><span className="reveal"> can. It creates happy employees and</span><br/><span className="reveal"> better quality work.</span>',
    },
    {
      title: "Collaborate",
      description:
        '<span className="reveal">The more we collaborate, the better the</span><br/><span className="reveal"> outcome is. If your organisation has</span><br/><span className="reveal"> individuals who feel isolated, everyone</span><br/><span className="reveal"> suffers. It’s important that the client- </span><br/><span className="reveal">agency relationship is viewed as a team </span><br/><span className="reveal">effort. It doesn’t help anyone to think of it </span><br/><span className="reveal">as “us” and “them.”</span>',
    },
    {
      title: "Competence ",
      description:
        '<span className="reveal">The DNA of our organization is to bring</span><br/><span className="reveal"> on the best. It’s important for a client to</span><br/><span className="reveal"> have confidence in our team so that they</span><br/><span className="reveal"> can rely on our expertise. We all have a</span><br/><span className="reveal"> thirst for knowledge, so we invest in</span><br/><span className="reveal"> learning to keep on top of industry</span><br/><span className="reveal"> leading trends to stay ahead of the curve.</span>',
    },
  ];

  useEffect(() => {
    const span = document.querySelectorAll(".about_values_description > span");
    span.forEach((s) => s.classList.add("reveal"));
  }, []);

  return (
    <section className="about_values">
      <div className="reveal">
        <h3 className="about_values_h3">How we do things </h3>
      </div>
      <div className="about_values_map">
        {values.map((el, idx) => (
          <div key={idx}>
            <h4 className="about_values_title reveal">{el.title}</h4>
            <p
              className="about_values_description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(el.description),
              }}
            ></p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Values;
