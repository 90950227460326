import React from "react";

const Outcome = () => {
  return (
    <div className="startup_outcome">
      <h4 className="reveal startup_outcome_h4 w1460">
        Whatever the outcome, you will have our ongoing assistance
      </h4>
      <h4 className="reveal startup_outcome_h4 w414">
        <span>Whatever the outcome, you will</span>
        <br />
        <span>have our ongoing assistance</span>
      </h4>
      <p className="startup_outcome_p w1460">
        <span className="reveal">
          With your idea now formulated in a refined product, ready to show off,
          we can assist you
        </span>
        <br />
        <span className="reveal">
          no matter where your project takes you. We can assist you with
          development and
        </span>
        <br />
        <span className="reveal">
          implementation or if you have a few holes to patch up, we can help you
          with
        </span>
        <br />
        <span className="reveal">
          amendments, sticking with you through iterations and further
          refinement. Don’t let the
        </span>
        <br />
        <span className="reveal">
          process stop you from the outcome, reach out and let’s get started.{" "}
        </span>
      </p>
      <p className="startup_outcome_p w414">
        <span className="reveal">
          With your idea now formulated in a refined{" "}
        </span>
        <br />
        <span className="reveal">
          product, ready to show off, we can assist{" "}
        </span>
        <br />
        <span className="reveal">you no matter where your project takes </span>
        <br />
        <span className="reveal">you. We can assist you with development </span>
        <br />
        <span className="reveal">and implementation or if you have a few </span>
        <br />
        <span className="reveal">holes to patch up, we can help you with </span>
        <br />
        <span className="reveal">amendments, sticking with you through </span>
        <br />
        <span className="reveal">
          iterations and further refinement. Don’t let{" "}
        </span>
        <br />
        <span className="reveal">the process stop you from the outcome, </span>
        <br />
        <span className="reveal">reach out and let’s get started.</span>
        <br />
      </p>
    </div>
  );
};

export default Outcome;
