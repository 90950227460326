import { React, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import designThinkingImg from "../../img/Solutions Page/Strategy/jx_designthinking_ strategy_workshop.png";
import uxuiImg from "../../img/Solutions Page/Strategy/jx_uxui_wireframes_design_product_strategy.png";
import digitalStrategyImg from "../../img/Solutions Page/Strategy/jx_design_strategy_team_collaboation_uxui_1174_v2.jpg";
import founderImg from "../../img/Solutions Page/Strategy/jx_dan_perera_founder.png";

const Strategy = () => {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  return (
    <>
      <VisibilitySensor
        partialVisibility
        offset={{ bottom: 100 }}
        active={!viewPortEntered}
        onChange={(isVisible) => {
          if (isVisible) {
            setViewPortEntered(true);
          }
        }}
        delayedCall
      >
        {({ isVisible }) => (
          <section
            className="solutions_center_content_section"
            style={{ marginBottom: 108 }}
          >
            <div style={{ marginRight: 104.5 }}>
              <h5
                className={`reveal solutions_center_content_h5 ${
                  isVisible ? "active" : ""
                }`}
              >
                Design Thinking Workshops
              </h5>
              <p className="solutions_center_content_p">
                <span
                  style={{ transitionDelay: "40ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  Design thinking is a creative way to solve
                </span>
                <span
                  style={{ transitionDelay: "60ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  problems. It is an engaging process in which you
                </span>
                <span
                  style={{ transitionDelay: "80ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  focus on customer needs to develop innovative
                </span>
                <span
                  style={{ transitionDelay: "100ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  solutions. We teach you how to apply the
                </span>
                <span
                  style={{ transitionDelay: "120ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  method and run workshops to help implement
                </span>
                <span
                  style={{ transitionDelay: "140ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  this process. We will be an extension of your
                </span>
                <span
                  style={{ transitionDelay: "160ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  team and a part of the process & solution,
                </span>
                <span
                  style={{ transitionDelay: "180ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  assisting your team to develop new strategies for
                </span>
                <span
                  style={{ transitionDelay: "200ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  thinking and problem solving.
                </span>
              </p>
            </div>
            <div
              style={{
                transitionDelay: "40ms",
                // background: `url(${designThinkingImg})`,
              }}
              className={`reveal solutions_center_content_img ${
                isVisible ? "active" : ""
              }`}
            >
              <img
                className="solutions_center_content_img"
                src={designThinkingImg}
                alt="Design Thinking Workshops"
              />
            </div>
          </section>
        )}
      </VisibilitySensor>

      <section
        style={{ marginBottom: 108 }}
        className="solutions_center_content_section"
      >
        <div
          className="reveal solutions_center_content_img"
          style={{ marginRight: 81 }}
        >
          <img
            className="solutions_center_content_img"
            src={uxuiImg}
            alt="UX/UI & Product Strategy"
          />
        </div>
        <div>
          <h5 className="reveal solutions_center_content_h5">
            UXUI & Product Strategy
          </h5>
          <p className="solutions_center_content_p">
            <span className="reveal">
              Keeping your users’ needs at the forefront,
            </span>
            <span className="reveal">your product goal will be formed and</span>
            <span className="reveal">defined. We will focus on bringing</span>
            <span className="reveal">
              conceptualization to fruition by outlining
            </span>
            <span className="reveal">
              who the users are, their pain points, and
            </span>
            <span className="reveal">
              how the product will address those pain
            </span>
            <span className="reveal">
              points. With these goals planned out, your
            </span>
            <span className="reveal">
              team will be coordinated, following the
            </span>
            <span className="reveal">same end goal cohesively.</span>
          </p>
        </div>
      </section>
      <section
        style={{ marginBottom: 108 }}
        className="solutions_center_content_section"
      >
        <div style={{ marginRight: 104.5 }}>
          <h5 className="reveal solutions_center_content_h5">
            Digital Strategy
          </h5>
          <p className="solutions_center_content_p">
            <span className="reveal">
              We focus on creating a plan that hones in
            </span>
            <span className="reveal">
              on improving users’ digital experience, and
            </span>
            <span className="reveal">
              creating a flexible system for an ever
            </span>
            <span className="reveal">
              -changing digital landscape. We will help
            </span>
            <span className="reveal">
              you identify the right digital strategy for
            </span>
            <span className="reveal">your business.</span>
          </p>
        </div>
        <div className="reveal solutions_center_content_img">
          <img
            className="solutions_center_content_img"
            src={digitalStrategyImg}
            alt="Digital Strategy Meeting"
          />
        </div>
      </section>
      <section className="solutions_center_content_section">
        <div
          className="reveal solutions_center_content_img"
          style={{ marginRight: 81 }}
        >
          <img
            className="solutions_center_content_img"
            src={founderImg}
            alt="Founder Dan Perera"
          />
        </div>
        <div>
          <h5 className="solutions_center_content_h5">
            <span className="reveal">Launch Design Practices</span>
            <br />
            <span className="reveal">Coaching & Training</span>
          </h5>
          <p className="solutions_center_content_p">
            <span className="reveal">
              With years of experience in this process,
            </span>
            <span className="reveal">
              we are able to get a new or existing design
            </span>
            <span className="reveal">
              team up to speed with the tool set needed
            </span>
            <span className="reveal">to get the job done efficiently and</span>
            <span className="reveal">
              effectively. We will be a part of your team,
            </span>
            <span className="reveal">
              continuously cultivating growth. Learn
            </span>
            <span className="reveal">
              from our experiences and have access to
            </span>
            <span className="reveal">advanced industry knowledge.</span>
          </p>
        </div>
      </section>
    </>
  );
};

export default Strategy;
