import React from "react";
import laImg from "./../../img/About Us Page/jx_locations_la_losangeles_design_uxui_agency.jpg";
import sfImg from "./../../img/About Us Page/jx_locations_sf_sanfrancisco_design_uxui_agency.jpg";

const Team = () => {
  const employees = [
    {
      name: "California",
      img: laImg,
      img2: sfImg,
      subtitle: "Our home base",
      css: { objectPosition: "50% 63%" },
      css2: { objectPosition: "50% 35%", position: "absolute", left: 0 },
    },
  ];

  return (
    <>
      <h3
        className="reveal about_team_h3 w1460"
        style={{ textAlign: "center", margin: "0 auto" }}
      >
        and the culture we're building
      </h3>
      <h3 className="about_team_h3 w414">
        <span className="reveal">and the culture</span>
        <br />
        <span className="reveal">we're building</span>
      </h3>
      <section className="about_team">
        {employees.map((el, idx) => (
          <div
            onMouseOver={(ev) => {
              let subtitle = ev.currentTarget.querySelector(
                ".about_team_subtitle"
              );
              subtitle.classList.add("hover");
            }}
            onMouseLeave={(ev) => {
              let subtitle = ev.currentTarget.querySelector(
                ".about_team_subtitle"
              );
              subtitle.classList.remove("hover");
            }}
            className="about_team_member"
            key={idx}
            style={el.style}
          >
            <div className="reveal">
              <div className="grow" style={{ position: "relative" }}>
                {el.img2 ? (
                  <>
                    <img
                      style={el.css}
                      className="about_team_img"
                      src={el.img}
                      alt="team"
                    />
                    <img
                      onMouseOver={(ev) => {
                        ev.currentTarget.classList.add("hidden");
                      }}
                      onMouseOut={(ev) => {
                        ev.currentTarget.classList.remove("hidden");
                      }}
                      className="about_team_img about_team_img_location"
                      src={el.img2}
                      alt="team"
                      style={el.css2}
                    />
                  </>
                ) : (
                  <img className="about_team_img" src={el.img} alt="team" />
                )}
              </div>
            </div>
            <p className="reveal about_team_text">{el.name}</p>
            <h5 className="about_team_subtitle">{el.subtitle}</h5>
          </div>
        ))}
      </section>
    </>
  );
};

export default Team;
