import { React, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import Strategy from "./Strategy";
import Design from "./Design";
import Development from "./Development";

const Center = () => {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const [selected, setSelected] = useState("Strategy");
  const handleSelect = (ev) => {
    let textContent = ev.currentTarget.textContent;
    setSelected(textContent);
  };
  return (
    <VisibilitySensor
      partialVisibility
      offset={{ bottom: 100 }}
      active={!viewPortEntered}
      onChange={(isVisible) => {
        if (isVisible) {
          setViewPortEntered(true);
        }
      }}
      delayedCall
    >
      {({ isVisible }) => (
        <div className="solutions_center">
          <div className="solutions_center_tabs">
            <h5
              onClick={handleSelect}
              style={{
                transitionDelay: "100ms",
              }}
              className={`reveal solutions_center_tabs_h5 ${
                isVisible ? "active" : ""
              }`}
            >
              <span
                style={{
                  fontWeight: selected === "Strategy" ? "700" : "",
                }}
                className="blueHover"
              >
                Strategy
              </span>
            </h5>
            <h5
              onClick={handleSelect}
              className={`reveal ${
                isVisible ? "active" : ""
              } solutions_center_tabs_h5`}
              style={{
                transitionDelay: "120ms",
              }}
            >
              <span
                style={{
                  fontWeight: selected === "Design" ? "700" : "",
                }}
                className="blueHover"
              >
                Design
              </span>
            </h5>
            <h5
              onClick={handleSelect}
              style={{
                transitionDelay: "140ms",
              }}
              className={`reveal ${
                isVisible ? "active" : ""
              } solutions_center_tabs_h5`}
            >
              <span
                style={{
                  fontWeight: selected === "Development" ? "700" : "",
                }}
                className="blueHover"
              >
                Development
              </span>
            </h5>
            <div
              style={{ transitionDelay: "160ms" }}
              className={`reveal solutions_center_tabs_line ${
                isVisible ? "active" : ""
              }`}
            >
              <div
                className={`solutions_center_tabs_selected ${selected}`}
              ></div>
            </div>
          </div>
          <div className="solutions_center_content">
            {selected === "Strategy" ? <Strategy /> : null}
            {selected === "Design" ? <Design /> : null}
            {selected === "Development" ? <Development /> : null}
          </div>
        </div>
      )}
    </VisibilitySensor>
  );
};

export default Center;
