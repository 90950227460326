import { React, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import closeIcon from "../img/misc/close-icon.svg";
import jxdesignred from "../img/misc/jxdesignred.svg";

const Header = () => {
  const [collapse, setCollapse] = useState(false);
  let activeStyle = {
    fontWeight: 700,
    color: "#1C75BC",
  };
  const handleHam = () => {
    setCollapse(!collapse);
  };
  return (
    <header>
      <>
        <Nav
          className={`navBarLinks ms-auto backdrop red ham ${
            collapse ? "show" : ""
          }`}
        >
          <a
            aria-label="Navigate to the JX Design Homepage"
            onClick={handleHam}
            href="/"
          >
            <img
              className="navBar_logo_ham"
              src={jxdesignred}
              alt="JX Design Logo red"
            />
          </a>
          <img
            onClick={handleHam}
            className="close-icon"
            src={closeIcon}
            alt="close icon"
          />
          <div>
            <NavLink
              onClick={handleHam}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              className="nav-link navLink"
              to="case-studies"
            >
              Work
            </NavLink>
            <NavLink
              onClick={handleHam}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              className="nav-link navLink"
              to="solutions"
            >
              Solutions
            </NavLink>
            <NavLink
              onClick={handleHam}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              className="nav-link navLink"
              to="startup-catalyst"
            >
              Startup Catalyst
            </NavLink>
            <NavLink
              onClick={handleHam}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              className="nav-link navLink"
              to="aboutus"
            >
              About Us
            </NavLink>
            <Nav.Link
              aria-label="Send an email to JX Design"
              className="navLink"
              href="mailto:Hey@jx.design"
            >
              Say hi
            </Nav.Link>
          </div>
          <div className="navbar_ham_talk">
            <h1 className="footer_h1">Let's talk.</h1>
            <a
              aria-label="Send an email to JX Design"
              className="footer_email"
              href="mailto:Hey@jx.design"
            >
              Hey@jx.design
            </a>
            <a
              aria-label="Give JX Design a call"
              href="tel:1 (661) 749 3778"
              className="footer_phone"
            >
              +1 (661) 749 3778
            </a>
          </div>
        </Nav>
      </>
      <Navbar
        className="sticky-nav navBar"
        bg="light"
        variant="light"
        expand="lg"
        fixed="top"
        collapseOnSelect
      >
        <Container className="nav-container">
          <Navbar.Brand
            href="/"
            aria-label="Navigate to the JX Design Homepage"
          >
            <div
              alt="jx_digital_design_agency_servicedesign_uxui"
              className="nav_jxLogo"
              style={{ marginLeft: -10 }}
            />
          </Navbar.Brand>
          <div className="navRight">
            <button
              aria-label="hamburger"
              className="hamburger"
              onClick={handleHam}
            ></button>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navBarLinks ms-auto">
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="nav-link navLink blueHover"
                  to="case-studies"
                >
                  Work
                </NavLink>
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="nav-link navLink blueHover"
                  to="solutions"
                >
                  Solutions
                </NavLink>
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="nav-link navLink blueHover"
                  to="startup-catalyst"
                >
                  Startup Catalyst
                </NavLink>
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="nav-link navLink blueHover"
                  to="aboutus"
                >
                  About Us
                </NavLink>
                <Nav.Link
                  className="navLink blueHover"
                  href="mailto:Hey@jx.design"
                >
                  Say hi
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
